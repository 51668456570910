.cardIconMargin {
  height: 30px;
  width: 32px;
  margin-right: 9px;
}

div > .pageTitles {
  font-weight: 700;
  font-size: 16px;
  text-transform: uppercase;
  color: #008848;
  margin-top: 33px;
  margin-bottom: 13px;
}

div > .subTitleTextRow {
  display: flex;

  margin-bottom: 20px;
}

div > .pageSubtitles {
  font-weight: 700;
  font-size: 14px;
  color: #333333;
}

div > .pageSubText {
  font-weight: 400;
  font-size: 10px;
  color: #333333;
}

div > .officeDropdown {
  margin-left: 10px;
  border-radius: 6px;
  opacity: 1;
  color: #e5e5e5;
  width: fit-content;
  height: 32px;
  float: right;
}

div > .dropdownTextValue {
  font-weight: 400;
  font-size: 11px;
  color: #757575;
}

ul > .menuItem[aria-selected='true'] {
  background-color: #3333331a !important;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 6px;
  font-weight: 600;
  font-size: 12px;
}

div > div > ul > .menuItem {
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 6px;
  font-weight: 400;
  font-size: 12px;
}

p > .infoIcon {
  width: 12px;
  height: 12px;
  color: #008848;
}

div > .infoFont {
  font-weight: 400;
  font-size: 10px;
  color: #333333;
  padding: 6px 9px;
  border-radius: 4px;
  margin: 17px 0px 32px 0px;
}
