div .containerProductImageDiv {
    padding: 5px;
    height: 100%;
}

div .containerProductImageLoaderDiv {
    display: flex;
    justify-content: center;
    align-items: center;
    height: inherit;
    padding: 5px;
}

.productListImgStyling {
    border-radius: 6px;
    height: 100%;
    object-fit: cover;
}