.cardGroupEntry {
  padding: 10px 16px;
  border: 0;
  &:nth-of-type(odd) {
    background-color: #f8f8f8;
  }
  &:nth-of-type(even) {
    background-color: #ffffff;
  }
}

div .invoiceEntry {
  display: grid;
  align-items: center;
  grid-template-columns: 0.3fr 0.3fr 0.4fr 0.4fr 1fr min-content;
  justify-items: flex-start;
}

div > .entryStyle {
  font-weight: 400;
  font-size: 11px;
  color: #777777;
}

div .entryDownloadStyle {
  padding: 4px 7px;
  border-radius: 6px;
  background-color: rgba(0, 161, 58, 0.1);

  font-weight: 600;
  font-size: 12px;
  text-decoration: underline;
  color: #00a13a;

  user-select: none;
  cursor: pointer;
}

div .reportDivStyle {
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-auto-flow: column;
  column-gap: 1rem;
}

div .reportDivStyle div {
  font-weight: 600;
  font-size: 12px;
  text-decoration: underline;
  color: #00532c;
  cursor: pointer;
  width: fit-content;
}

div > .downloadStyle {
  width: 20px;
  height: 20px;
  color: #878787;
  background-color: #008848;
  border-radius: 4px;
  border: 1px solid #008848;
  color: #ffffff;
  padding: 3px 2px 2px 2px;
  cursor: pointer;
}

.searchColStyle {
  display: flex;
  align-items: center;
  width: 4%;
}

div > .searchStyle {
  width: 20px;
  height: 20px;
  color: #ffffff;
  background-color: #a5cd38;
  border: 2px solid #a5cd38;
  border-radius: 4px;
  cursor: pointer;
}
