@font-face {
  font-family: 'MobintegElectedFont';
  src: url('./assets/fonts/SourceSansPro-Regular.ttf');
}

@font-face {
  font-family: 'MobintegElectedFont';
  src: url('./assets/fonts/SourceSansPro-Italic.ttf');
  font-style: italic;
}

@font-face {
  font-family: 'MobintegElectedFont';
  src: url('./assets/fonts/SourceSansPro-Bold.ttf');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'MobintegElectedFont';
  src: url('./assets/fonts/SourceSansPro-BoldItalic.ttf');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'MobintegElectedFont';
  src: url('./assets/fonts/SourceSansPro-SemiBold.ttf');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'MobintegElectedFont';
  src: url('./assets/fonts/SourceSansPro-SemiBoldItalic.ttf');
  font-weight: 600;
  font-style: italic;
}

body,
html,
* {
  font-family: 'MobintegElectedFont' !important;
  /* font-family: Source Sans Pro !important; */
}

.body {
  background-color: #f6f6f6;
}
