div .cardStyle {
  border: 1px solid #e8e8e8 !important;
  box-shadow: 0px 0px 4px rgba(215, 215, 215, 0.5);
}

div .containerHeaderDiv {
  border: 0;
  border-bottom: 1px solid #efefef;
  background-color: #ffffff;
  padding-top: 14px;
  padding-bottom: 10px;
  border-top-left-radius: 6px !important;
  border-top-right-radius: 6px !important;
}

div .containerHeaderDiv .headerStyle {
  display: grid;
  grid-template-columns: 0.305fr 0.3fr 0.41fr 0.4fr 1fr min-content;
  align-items: center;
}

div .headerStyle {
  font-weight: 700;
  font-size: 11px;
  text-transform: uppercase;
  color: #333333;
}

div .invoiceEntriesContainerDiv {
  max-height: 50vh;
  width: 100%;
  overflow-y: auto;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

div .headerStyle div:last-child {
  text-align: end;
}