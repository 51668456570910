div .cardStyle {
  padding: 38px 31px 45px 35px;
  display: flex;
  justify-content: center;
  border-width: 0px;
  box-shadow: 0px 0px 6px rgba(205, 205, 205, 0.631372549);
  background-image: url('../../../assets/invoice_icons/budget-bg.svg');
  background-repeat: no-repeat;
  background-size: cover;
}

div .contentRowStyle {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  div:last-child {
    justify-content: flex-end;
  }
}

div .cardTitle {
  font-weight: 700;
  font-size: 37px;
  color: #333333;
  margin-bottom: -12px;
}

div .cardSubtitle {
  font-weight: 400;
  font-size: 12px;
  color: #333333;
  opacity: 0.5;
}

div .budgetDivStyle {
  display: flex;
  align-items: center;
  white-space: nowrap;
}

div .budgetIconStyle {
  margin-right: 12px;
}

div .budgetValueStyle {
  display: flex;
  justify-content: flex-end;
  font-weight: 700;
  font-size: 30px;
  color: #a5cd38;
  margin-bottom: -12px;
}

div .totalBudgetTextStyle {
  display: flex;
  justify-content: flex-end;
  font-weight: 400;
  font-size: 14px;
  color: #a5cd38;
}

@media (max-width: 885px) {
  div .contentRowStyle {
    justify-items: center;
    grid-template-columns: 1fr;
    row-gap: 10px;
  }
}
