div .titleStyle {
  font-weight: 700;
  font-size: 16px;
  text-transform: uppercase;
  color: #008848;
  margin-bottom: 16px;
}

div .subtitleStyle {
  font-weight: 700;
  font-size: 14px;
  color: #333333;
}

div .subtitleTextStyle {
  font-weight: 400;
  font-size: 10px;
  color: #333333;
  margin-bottom: 19px;
}
