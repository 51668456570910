div .modalStyle {
  padding: 40px 45px 60px 45px;
}

div .headerContainerDiv {
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
}

div .headerTitleDiv {
  display: grid;
  grid-template-columns: 1fr min-content;
  align-items: center;

  font-weight: 700;
  font-size: 20px;
  color: #333333;
}

div .closeIconDiv {
  cursor: pointer;
}

div .headerNameDiv {
  font-weight: 700;
  font-size: 14px;
  color: #a5cd38;
}

div .descriptionContainerDiv {
  margin-top: 25px;
  margin-bottom: 25px;
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 25px;
  font-weight: 400;
  font-size: 14px;
  color: #333333;
}

div .formContainerDiv {
  margin-bottom: 33px;
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;
  align-items: end;
}

div .yearFormContainerDiv {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  align-items: end;
}

div .helperTextStyle {
  font-weight: 600;
  font-size: 10px;
  text-transform: uppercase;
  color: #acacac;
  margin-bottom: 6px;
  margin-left: 2px;
}

div > .formControlInput {
  margin-top: 5px;
}

div .selectStyle {
  height: 28px;
  padding-bottom: 6px;
  font-size: 12px;
  font-weight: 600;
  color: #333333;
}

ul > .menuItem[aria-selected='true'] {
  background-color: #3333331a !important;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 6px;
  font-weight: 600;
  font-size: 12px;
}

div > div > ul > .menuItem {
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 6px;
  font-weight: 400;
  font-size: 12px;
}

div .submitButton {
  padding: 8px 14px;
  text-align: center;
  border-radius: 8px;
  background-color: #008848;
  outline: 1px solid #008848;

  font-weight: 700;
  font-size: 12px;
  color: #ffffff;
  user-select: none;
  cursor: pointer;
  &:hover {
    background-color: #ffffff;
    color: #008848;
  }
}

div .disabledSubmitButton {
  padding: 8px 14px;
  text-align: center;
  border-radius: 8px;
  background-color: #dddeda;
  outline: 1px solid #dddeda;

  font-weight: 700;
  font-size: 12px;
  color: #ffffff;
  user-select: none;
  pointer-events: none;
  cursor: default;
}

div .generatedReportContainerDiv {
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  row-gap: 14px;

  font-weight: 400;
  font-size: 14px;
  color: #333333;
}

div .reportContainerDiv {
  border-radius: 4px;
  background-color: #f9f9f9;
  padding: 6px 8px;

  font-weight: 400;
  font-size: 13px;
  color: #333333;

  display: grid;
  grid-template-columns: 1fr 30%;
  align-items: center;
}

div .reportYearMonth {
  font-weight: 700;
  font-size: 13px;
  color: #333333;
}

div .downloadButton {
  padding: 6px 9px;

  border-radius: 6px;
  background-color: #00532c;

  display: grid;
  grid-template-columns: min-content 1fr;
  align-items: center;
  justify-items: center;
  column-gap: 5px;

  font-weight: 700;
  font-size: 11px;
  color: #ffffff;
  user-select: none;
  cursor: pointer;
}

div .singleFieldContainer {
  grid-column: span 2;
}

div .doubleFieldContainer {
  display: grid;
  grid-column: span 2;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  align-items: end;
}
