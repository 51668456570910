div .parentDiv {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  column-gap: 28px;
}

.firstColPadding {
  padding-left: 0px;
  padding-right: 6px;
  padding-bottom: 8px;
}

.middleColPadding {
  padding-bottom: 8px;
}

.lastColPadding {
  padding-right: 0px;
  padding-left: 6px;
  padding-bottom: 8px;
}

h5 > .cardIconMargin {
  height: 30px;
  width: 32px;
  margin-right: 9px;
}

div .containerProductImageDiv {
  padding: 5px;
  height: 100%;
}

div .containerProductImageLoaderDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  height: inherit;
  padding: 5px;
}

.productListImgStyling {
  border-radius: 6px;
  height: 100%;
  object-fit: cover;
}

///
.clientCard {
  padding: 8px;
  height: 100%;
  max-height: 165px;
  border-radius: 6px;
  border-width: 0px;
  box-shadow: 0px 0px 6px #cdcdcda1;
}

div .contentContainerDiv {
  display: grid;
  grid-template-columns: 1fr 2fr;
  column-gap: 30px;
  justify-content: flex-start;
  align-items: center;
}

.cardColDashBoardIcons {
  display: flex;
  justify-content: center;
  align-items: center;
}

div > .cardDashBoardTitle {
  font-weight: 700;
  font-size: 32px;
  color: #00532c;
  margin-bottom: -5px;
}

div > .cardDashBoardDetails {
  font-size: 13px;
  color: #b9b9b9;
}

div .imageCardStyle {
  max-height: 165px;
  height: 100%;
  box-shadow: 0px 0px 6px #cdcdcda1;
}

@media (max-width: 1150px) {
  div .clientCard {
    max-height: 100%;
  }

  div .imageCardStyle {
    max-height: 100%;
  }
}
