.clientCard {
  padding-top: 21px;
  padding-left: 17px;
  padding-bottom: 15px;
  padding-right: 17px;
  height: 100%;
  max-height: 165px;
  border-radius: 6px;
  border-width: 0px;
  box-shadow: 0px 0px 6px #cdcdcda1;
}

.cardTitle {
  font-size: 20px;
  color: #333333;
  font-weight: 700;
  font-family: 'Source Sans Pro';
}

.cardBody {
  padding: 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

div > .chipLayout {
  border-radius: 2px;
  vertical-align: middle;
  height: 24px;
  margin-right: 6px;
  border-color: #a5cd38;
  background-color: #a5cd38;
  color: #ffffff;
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 16px;
  padding-left: 9.4px;
  padding-right: 9.4px;
  width: fit-content;
  span {
    padding: 0px;
  }
}

div .cardDetailsContainerDiv {
  display: grid;
  grid-template-columns: 1fr 1fr 2fr;
}

div > .cardDetailsTitle {
  font-size: 10px;
  color: #939598;
  font-weight: 400;
}

.cardBulletpointMarker {
  padding-left: 15px;
}

div > .cardBulletpointDetail,
ul > .cardBulletpointDetail {
  font-weight: 400;
  font-size: 14px;
  color: #333333;
}

p > .columnmenustatusActive {
  width: 7px;
  height: 7px;
  background-color: #a5cd38;
  margin-right: 6px;
  border-radius: 50%;
  display: inline-block;
}

p > .columnmenustatusInactive {
  width: 7px;
  height: 7px;
  background-color: #333333;
  margin-right: 6px;
  border-radius: 50%;
  display: inline-block;
}

@media (max-width: 1150px) {
  div .clientCard {
    max-height: 100%;
  }
}
